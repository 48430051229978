@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: white;
}

/* Custom CSS to hide scrollbar */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


.hover-style:hover{
    background-color: #566067 !important;
}

.primary-color{
    color: #3F464B;
}

.secondary-color{
    color: #566067;
}
.hover-primary-color:hover{
    background-color: #3F464B;
    color:white;
}