@import url("https://fonts.googleapis.com/css2?family=Fasthand&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Delicious+Handrawn&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cedarville+Cursive&display=swap");

.placeholderBlock {
  padding: 0px;
  position: absolute;
  border-style: dashed;
  width: 150px;
  height: 60px;
  background: #daebe0;
  text-align: center;
  justify-content: center;
  border-width: 0.2px;
}

.dragCursor {
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAASElEQVR4nGNgwAMkJSUbpKSkOvCpIaT5PxSTbogUQjMYMwxeIIXmVFIxA8UGDDyQGg0DnIDi6JKUlCxHMqCeZAOghjSAMD5FAKfeaURdUFxCAAAAAElFTkSuQmCC"), auto;
}

.react-datepicker__input-container {
  position: initial !important;
}

.widgets {
  -webkit-user-select: none;
  /* Disable text selection in WebKit browsers */
  -moz-user-select: none;
  user-select: none;
}

.inputPlaceholder {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  /* font-size: 12px; */
  border: 1px solid #007bff;
  border-radius: 2px;
}

.radioButton {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
  border: 1px solid #007bff;
  border-radius: 50px;
}

.inputPlaceholder:focus {
  outline: none;
  border: 1px solid #188ae2;
}

.drodown-input {
  padding: 5px;
  width: 100%;
  font-size: 0.75rem;
  border: 1px solid #8ad5ce;
  /* border: 1px solid #e2dddd; */
  /* background-color: #e0e5f5; */
  color: black;
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-weight: inherit;
  overflow: visible;
}

.drodown-input:focus {
  outline: none;
  border: 1px solid #099b8d;
}

.dropdownAdd {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);

  color: white;
  font-weight: 600;
  font-size: 13px;
  border: none;
  margin-left: 10px;
}

.dropdownAdd:focus {
  outline: none;
}

.dropdownList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.signatureCanvas {
  border: 2px solid #888;
  background-color: rgb(255, 255, 255);
  width: 460px;
  height: 184px;
}

.intialSignatureCanvas {
  border: 2px solid #888;
  background-color: rgb(255, 255, 255);
  width: 150px;
  height: 150px;
}

.intialSignature {
  border: 2px solid #888;
  background-color: rgb(255, 255, 255);
  width: 183px;
  height: 183px;
}

.penContainerDefault {
  width: 460px;
}

.penContainerInitial {
  width: 183px;
}

.borderResize {
  position: absolute;
  display: inline-block;
  width: 14px;
  height: 14px;
}

.borderResize :hover {
  cursor: sw-resize;
}

.dropdown-date-menu {
  min-width: 7rem;
}

.mailBtn {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
  padding: 3px 15px !important;
  background-color: rgb(79 190 241);
  border: none !important;
  color: white !important;
}

.mailBtn:hover {
  box-shadow: 0 2px 4px rgba(154, 36, 36, 0.1), 0 2px 4px rgba(0, 0, 0, 0.18);
}

.signatureBtn {
  border: 1.5px solid #7A7C81;
  border-radius: 3px;
  display: flex;
  padding: 0px;
  justify-content: space-between;
  background: white;
  transition-duration: 0.4s;
  cursor: all-scroll;
  width: 150px;
  height: 30px;
  color: black;
}

.disableSign {
  border: 1.5px solid #7A7C81;
  margin-bottom: 10px;
  border-radius: 3px;
  display: flex;
  padding: 0px;
  justify-content: space-between;
  background: white;
  transition-duration: 0.4s;
  cursor: all-scroll;
  width: 150px;
  height: 30px;
  color: black;
  border: 1px solid rgb(203, 203, 203);
  cursor: text;
}

.emailInput {
  padding: 10px;
  border: 1.5px solid gray;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
  padding-bottom: 20px;
}

.emailInput:focus {
  outline: none;
  border: 1.5px solid #7A7C81;
}

.addEmail {
  padding: "0px";
  border: 1.5px solid #7A7C81;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
}

.addEmail,
.emailInput {
  outline: none;
}

.addEmailInput {
  border: none;
  outline: none;
}

.emailChip {
  background-color: #7A7C81;
  margin: 4px;
  border-radius: 10px;
  padding: 5px 10px;
}

.addEmailInput {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  font-size: 15px;
}

.addEmailInput:focus {
  outline: none;
  border: none;
}

.signatureInput {
  width: 80%;
  border-radius: 4px;
  font-size: 20px;
  border: 1px solid #e2dddd;
  color: black;
  font-family: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-weight: inherit;
  overflow: visible;
  height: 30px;
}

.signatureInput:focus {
  outline: none;
  border: 1px solid blue;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.resizable-box-overlay {
  position: absolute;
  border: 2px solid #ddd;
  overflow: hidden;
  background-color: white;
}

.box-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.close-button {
  align-self: flex-end;
  margin: 8px;
  cursor: pointer;
}

.placeholdCloseBtn {
  position: absolute;
  right: -5px;
  top: -5px;
  border-radius: 100%;
  font-size: 10px;
  cursor: pointer;
  padding: 0px 5.1px 1px 5px;
}

.signCloseBtn {
  position: absolute;

  font-size: 14px;
  cursor: pointer;
  z-index: 2;
  background: white;
}

.signCopy {
  position: absolute;

  font-size: 13px;
  cursor: pointer;
  z-index: 2;
  background: white;
}

.signUserIcon {
  position: absolute;

  font-size: 13px;
  cursor: pointer;
  z-index: 2;
  background: white;
}

.settingIcon {
  position: absolute;

  font-size: 13px;
  cursor: pointer;
  z-index: 2;
  background: white;
}

.ScrollbarsCustom-Track {
  width: 4px !important;
}

.signYourselfBlock {
  padding: 0px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.finishBtn {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
  padding: 3px 30px;
  color: white;
  font-weight: 500 !important;
  font-size: 14px !important;
  border: none;
  margin-left: 10px;
  border-radius: 2px;
}

.sendMail {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
  padding: 3px 30px;
  color: white;
  font-weight: 500 !important;
  font-size: 14px !important;
  border: none;
  margin-left: 10px;
}

.finishBtn:focus {
  border: none;
  outline: none;
}

.finishBtn:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.18);
}

.saveBtn {
  padding: 3px 18px !important;
}

.disabledFinish {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
  padding: 3px 30px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border: none;
  margin-left: 10px;
}

.sendHover:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.18);
  color: white;
}

.defaultSignBox {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
  width: 90%;
  height: 111px;
  padding: 8px 20px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 2px;
  margin-left: 10px;
}

.defaultBtn {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
  padding: 3px 26px;
  color: black;
  font-weight: 500;
  font-size: 14px;
  border: 1px solid gray;
  border-radius: 2px;
  margin-left: 10px;
  border: "none";
}

.defaultBtn:focus {
  border: none;
  outline: none;
}


.signerList {
  overflow-y: scroll;
  max-height: 180px;
}

.signerList::-webkit-scrollbar {
  display: none;
}

.showScroll {
  height: 100%;
  overflow: scroll;
}

.showScroll::-webkit-scrollbar {
  display: none;
}

.hideScroll {
  height: 100%;
  overflow: scroll;
}

.signedStyle {
  color: white;
  padding: 5px;
  font-family: sans-serif;
  font-size: 15px !important;
}

.userName {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #424242 !important;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.disabled {
  opacity: 0.5;
  /* Example: reduce opacity to visually indicate disabled state */
  pointer-events: none;
  /* Prevents mouse clicks and other events */
  /* background-color: #888; */
}

.useEmail {
  font-size: 10px;
  color: #424242;
  font-weight: 500;
  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.spanTagHead {
  color: white !important;
  font-size: 17px;
  font-weight: 500;
}

.pTagBody {
  font-size: 15px !important;
}

.signTab {
  cursor: pointer !important;
  font-weight: 400 !important;
  margin: 7px !important;
  font-size: 15px !important;
}

.cancelBtn {
  color: black;
  border: 1px solid #ccc;
}

.draggable-button {
  padding: 10px 20px;
  background: #3498db;
  color: #fff;
  border: none;
  cursor: grab;
  transition: transform 0.2s;
}

.draggable-button.dragging {
  opacity: 0.5;
  /* Reduce opacity while dragging */
  transform: scale(1.1);
  /* Apply a scaling effect while dragging */
}

.signatureContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ebebeb;
  position: "relative";
}


.signerComponent {
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  width: 180px;
  background-color: white;
  height: 100%;
}

.modalBody {
  padding: 1rem !important;
  padding-top: 2rem !important;
}

.signLayoutContainer1 {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signLayoutContainer2 {
  display: none;
}

.preBtn1 {
  display: flex;
  align-items: center;
}

.preBtn2 {
  display: none;
}

.headerBtn2 {
  display: none;
}

.uploadImgLogo {
  font-size: 50px;
}

.DropdownMenuContent,
.DropdownMenuSubContent {
  min-width: 130px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 5px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 30 !important;
}

.DropdownMenuContent[data-side="top"],
.DropdownMenuSubContent[data-side="top"] {
  animation-name: slideDownAndFade;
}

.DropdownMenuContent[data-side="right"],
.DropdownMenuSubContent[data-side="right"] {
  animation-name: slideLeftAndFade;
}

.DropdownMenuContent[data-side="bottom"],
.DropdownMenuSubContent[data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.DropdownMenuContent[data-side="left"],
.DropdownMenuSubContent[data-side="left"] {
  animation-name: slideRightAndFade;
}

.DropdownMenuItem {
  font-size: 13px;
  line-height: 1;
  color: black;
  background-color: #fff;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 5px;
  position: relative;
  padding-left: 20px;
  user-select: none;
  outline: none;
  border: none;
}

.SelectTrigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 70px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  border: none;
  background-color: #887abf;

  width: 100px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.selectEmail {
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 1;
  height: 35px;
  gap: 5px;
  border: none;
  background-color: #887abf;
  width: 200px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

option {
  overflow-y: scroll;
}

.SelectTrigger:hover {
  border: none;
  outline: none;
}

.selectEmail:hover {
  border: none;
  outline: none;
}

.SelectTrigger:focus {
  /* box-shadow: 0 0 0 2px black; */
  border: none;
  outline: none;
}

.selectEmail:focus {
  /* box-shadow: 0 0 0 2px black; */
  border: none;
  outline: none;
}

.SelectTrigger[data-placeholder] {
  color: black;
}

.SelectIcon {
  color: Var(--violet-11);
}

.SelectContent {
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.SelectViewport {
  padding: 5px;
}

.SelectItem {
  font-size: 13px;
  line-height: 1;
  color: var(--violet-11);
  border-radius: 3px;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;
  z-index: 10 !important;
}

.SelectItem[data-disabled] {
  color: var(--mauve-8);
  pointer-events: none;
}

.SelectItem[data-highlighted] {
  outline: none;
  background-color: var(--violet-9);
  color: var(--violet-1);
}

.SelectLabel {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
  color: var(--mauve-11);
}

.SelectSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.SelectItemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.SelectScrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: var(--violet-11);
  cursor: default;
}

.fontOptionContainer {
  border: 1px solid #d6d3d3;
  margin: 10px 0px 5px 0px;
}

.dropDownStyle {
  width: auto;
}

.autoSignScroll {
  overflow-y: auto;
}

.autoSignScroll::-webkit-scrollbar {
  display: none;
}

.validateInputText {
  width: 76%;
}

.regxSelect {
  border: 1px solid #8ad5ce;
  width: 80%;
}

.regxSelect:focus {
  outline: none;
  border: 1px solid #8ad5ce;
}

.radioGroupInput {
  width: 100%;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.labelWidthDesktop {
  min-width: 100%;
  min-height: 100%;
}

.labelWidthMobile {
  width: 100%;
  height: 100%;
}

.labelTextArea {
  font-size: 12px;
  border: 1px solid #007bff;
  outline: none;
  z-index: 999;
  border-radius: 2px;
  resize: none;
  overflow: hidden;
}

.labelTextArea:focus {
  outline: none;
  border: 1px solid #188ae2;
}

@media screen and (max-width: 766px) {
  .validateInputText {
    width: 93%;
  }

  .regxSelect {
    width: 100%;
  }

  .validateText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .showPages {
    display: none;
  }

  .dropdown-menu {
    min-width: 0rem !important;
  }

  .signatureContainer {
    display: flex;
    flex-direction: column;
  }

  .signerComponent {
    display: none;
  }

  .preBtn1 {
    display: none;
  }

  .preBtn2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ebebeb;
    padding: 5px 10px;
  }

  #navbar {
    overflow: hidden;
    z-index: 49;
  }

  /* Navbar links */
  #navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }

  #navbar a.active {
    background-color: #4caf50;
    color: white;
  }

  /* Page content */
  .content {
    padding: 16px;
  }

  /* The sticky class is added to the navbar
  with JS when it reaches its scroll position */
  .stickyHead {
    top: 50px;
    left: 0.9rem;
  }

  .stickySignerHead {
    position: fixed;
  }

  .stickyfooter {
    position: fixed;
    z-index: 99 !important;
    bottom: 0px;
    right: 0rem;
  }

  /* Add some top padding to the page content
  to prevent sudden quick movement (as the
  navigation bar gets a new position at the top of the
  page (position:fixed and top:0) */
  .stickyHead+.content {
    padding-top: 60px;
  }

  .signLayoutContainer2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 5px;
    padding: 2px 5px 10px 5px;
    position: sticky;
    align-items: center;
  }

  .headerBtn {
    display: none;
  }

  .headerBtn2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    margin-top: 5px;
  }

  .mobileHead {
    display: flex;
  }

  .signatureText {
    margin-right: 5px;
    font-size: 12px;
  }
}

@media screen and (max-width: 487px) and (min-width: 351px) {
  .signatureCanvas {
    width: 300px;
    height: 120px;
  }

  .penContainerDefault {
    width: 300px;
  }

  .uploadImgLogo {
    font-size: 30px;
  }

  .hidePdf {
    display: none;
  }

  .mobileHead {
    display: flex;
  }

  .signTab {
    font-weight: 500 !important;

    font-size: 12px !important;
  }
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 350px) and (min-width: 311px) {
  .signatureCanvas {
    width: 280px;
    height: 112px;
  }

  .penContainerDefault {
    width: 280px;
  }

  .hidePdf {
    display: none;
  }

  .signTab {
    font-weight: 500 !important;

    font-size: 10px !important;
  }

  .uploadImgLogo {
    font-size: 25px;
  }

  .uploadImg {
    font-size: 10px !important;
  }

  .mobileHead {
    display: flex;
  }
}

@media screen and (max-width: 310px) {
  .signatureCanvas {
    width: 230px;
    height: 92px;
  }

  .penContainerDefault {
    width: 230px;
  }

  .hidePdf {
    display: none;
  }

  .signTab {
    font-weight: 500 !important;

    font-size: 8px !important;
  }

  .uploadImgLogo {
    font-size: 20px;
  }

  .uploadImg {
    font-size: 10px !important;
  }

  .mobileHead {
    display: flex;
  }
}

.signature-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
}

.signature-backdrop.fade.in {
  opacity: 0.5;
}

.modaloverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  z-index: 900;
}

.modalcontainer {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 901;
  max-height: 80%;
  min-width: 500px;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
}

.dropdownModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 901;
  max-height: 80%;
  min-width: 40%;
  overflow-y: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
}

/* Hide scrollbar for Webkit browsers */
.modalcontainer::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Webkit browsers */
.dropdownModal::-webkit-scrollbar {
  display: none;
}

.modalheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  color: #ffffff;
}

/* Styling for the modal title */
.modaltitle {
  font-size: 1.2rem;
  font-weight: normal;
}

/* Styling for the close button */
.closebtn {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.defaultOptions {
  font-size: 12px;
  margin-left: 5px;
}

.defaultOptions:focus {
  outline: none;
}

@media (max-width: 767px) {
  .modalcontainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    max-height: 80%;
    min-width: 90%;
    overflow-y: auto;
  }

  .signatureBtn {
    width: max-content;
  }

  .dropdownModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    max-height: 80%;
    min-width: 60%;
    overflow-y: auto;
  }
}

@media (max-width: 464px) {
  .dropdownModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    max-height: 80%;
    min-width: 78%;
    overflow-y: auto;
  }
}